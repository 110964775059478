import React from "react";
import PropTypes from "prop-types";

import TypingIndicator from "./HelperComponets/TypingIndicator";
import MessageBarReply from "./HelperComponets/MessageBarReply";
import MessageBarAttachments from "./HelperComponets/MessageBarAttachments";
import MessageBarTextField from "./HelperComponets/MessageBarTextField";
import MessageBarActions from "./HelperComponets/MessageBarActions";
import MessageVoiceMessage from "./HelperComponets/MessageVoiceMessage";

// style
import "../../../Styles/Theme/Theme.css";
import "./Styles/ChatMessageBar.css";

const ChatMessageBar = ({
  handleClickOnRepliedMessage = () => { },
  msg,
  setMsg,
  type = "TextMessage",
  setHasEmojiDropdown,
  onChange = () => { },
  handleCallTyping,
  handleCallTypingOff,
  handleEnter,
  // MessageBarTextField
  placeholder = "Type a message...",
  className = "",
  hasMantionDropdown = false,
  hasEmojiDropdown = false,
  // Reply messageBar
  isMessageBarReply = false,
  MessageBarReplyType = "MessageReply",
  MessageBarReplySrc = "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  MessageBarReplyAvtarName = "Anton Reponen",
  MessageBarReplyReplyText = "Medium short text msg",
  MessageBarReplyAlt = "images",
  messageReplyHandleCloseClick,
  // MessageBarAttachments
  isMessageBarAttachments = false,
  hasImagesattachment = false,
  hasVideosattachment = false,
  hasFilesattachment = false,
  hasVoiceattachment = false,
  MessageBarAttachmentsImagesSrc = [
    "https://mds-community.s3.amazonaws.com/uploads/users/profile/original-64b7745f39249e342e0bf769-profile-2023-09-05T16:23:12.455901.png",
  ],
  MessageBarAttachmentsVideosSrc = [],

  MessageBarAttachmentsAlt = "images",
  MessageBarAttachmentsDuration = "2:15",
  MessageBarAttachmentsFiles = [
    {
      fileName: "filename.svg",
      size: "55KB",
    },
  ],
  audioBlobData = "",
  handleCloseAttachment = () => { },
  handleOnClickVoiceMessage = () => { },
  handleOnClickCloseVoiceMessage = () => { },
  handleClickStopRecording = () => { },
  isVoiceMessageSelected = false,
  isTypingIndicator = false,
  TypingIndicatorType = "name",
  TypingIndicatorName = "Jack",
  TypingIndicatorMembersName = "Peter",
  TypingIndicatorCount = 3,
  isFilled = false,
  allUsersList,
  AttachmentClickHandle,
  hasMentionDropdown,
  setHasMentionDropdown,
  MentionClickHandle,
  EmojiIconClickHandle,
  handleSelectEmoji,
  handlePreviewClick,
  isEmojiOpenSelect,
  setIsEmojiSelect,
  sendBtnClick,
  setSendBtnClick,
  editMessageState,
  setEditMessageState,
  editorClear,
  setEditorClear,
  uploadErrFlag,
  handleCopyPasteImage,
  mentionedUserIds,
  setMentionedUserIds
}) => {
  return (
    <div className="messagebar-sec">
      <>
        {isTypingIndicator && (
          <TypingIndicator
            count={TypingIndicatorCount}
            membersName={TypingIndicatorMembersName}
            name={TypingIndicatorName}
            type={TypingIndicatorType}
          />
        )}
        {type === "TextMessage" && !isVoiceMessageSelected ? (
          <>
            <div
              className={`messagebar-container ${editMessageState ? "edit-message-cls" : ""
                }`}
            >
              {isMessageBarReply && (
                <div className="messagebar-reply-container">
                  <MessageBarReply
                    handleClickOnRepliedMessage={handleClickOnRepliedMessage}
                    alt={MessageBarReplyAlt}
                    avtarName={MessageBarReplyAvtarName}
                    handleClick={messageReplyHandleCloseClick}
                    replyText={MessageBarReplyReplyText}
                    src={MessageBarReplySrc}
                    type={MessageBarReplyType}
                  />
                </div>
              )}
              {isMessageBarAttachments && (
                <div className="messagebar-attachment-container">
                  <MessageBarAttachments
                    alt={MessageBarAttachmentsAlt}
                    duration={MessageBarAttachmentsDuration}
                    files={MessageBarAttachmentsFiles}
                    audioBlobData={audioBlobData}
                    hasImages={hasImagesattachment}
                    hasVideos={hasVideosattachment}
                    hasFiles={hasFilesattachment}
                    hasVoice={hasVoiceattachment}
                    imageSrc={MessageBarAttachmentsImagesSrc}
                    videoSrc={MessageBarAttachmentsVideosSrc}
                    handleClose={handleCloseAttachment}
                    handlePreviewClick={handlePreviewClick}
                  />
                </div>
              )}

              <div className="messagebar-typing-container">
                <MessageBarTextField
                  className={className}
                  placeholder={placeholder}
                  hasMentionDropdown={hasMentionDropdown}
                  allUsersList={allUsersList}
                  setHasMentionDropdown={setHasMentionDropdown}
                  onChange={onChange}
                  msg={msg}
                  setMsg={setMsg}
                  handleCallTyping={handleCallTyping}
                  handleCallTypingOff={handleCallTypingOff}
                  handleEnter={handleEnter}
                  isEmojiOpenSelect={isEmojiOpenSelect}
                  setIsEmojiSelect={setIsEmojiSelect}
                  sendBtnClick={sendBtnClick}
                  setSendBtnClick={setSendBtnClick}
                  editMessageState={editMessageState}
                  setEditMessageState={setEditMessageState}
                  editorClear={editorClear}
                  setEditorClear={setEditorClear}
                  uploadErrFlag={uploadErrFlag}
                  handleCopyPasteImage={handleCopyPasteImage}
                  mentionedUserIds={mentionedUserIds}
                  setMentionedUserIds={setMentionedUserIds}
                />
              </div>
              <div className="messagebar-actions-container">
                <MessageBarActions
                  EmojiIconClickHandle={EmojiIconClickHandle}
                  hasEmojiDropdown={hasEmojiDropdown}
                  setHasEmojiDropdown={setHasEmojiDropdown}
                  MentionClickHandle={MentionClickHandle}
                  hasMentionDropdown={hasMentionDropdown}
                  setHasMentionDropdown={setHasMentionDropdown}
                  isFilled={
                    isVoiceMessageSelected ||
                      (msg && msg.length) ||
                      isMessageBarAttachments
                      ? true
                      : false
                  }
                  handleOnClickVoiceMessage={handleOnClickVoiceMessage}
                  AttachmentClickHandle={AttachmentClickHandle}
                  handleOnClickSend={() => {
                    handleEnter(undefined, true);
                  }}
                  handleSelectEmoji={handleSelectEmoji}
                />
              </div>
            </div>
          </>
        ) : (
          <>
            <div className="messagebar-container">
              <div className="messagebar-voice-inner">
                <MessageVoiceMessage
                  handleClick={handleOnClickCloseVoiceMessage}
                  handleClickStopRecording={handleClickStopRecording}
                />
              </div>
            </div>
          </>
        )}
      </>
    </div>
  );
};

ChatMessageBar.propTypes = {
  type: PropTypes.oneOf(["TextMessage", "VoiceMessage"]),
  placeholder: PropTypes.string,
  className: PropTypes.string,
  hasMantionDropdown: PropTypes.bool,
  hasEmojiDropdown: PropTypes.bool,
  setHasEmojiDropdown: PropTypes.func.isRequired,
  onChange: PropTypes.func,
  // Reply messageBar
  isMessageBarReply: PropTypes.bool,
  handleChange: PropTypes.func,
  MessageBarReplyType: PropTypes.oneOf([
    "MessageReply",
    "ImageReply",
    "VideoReply",
    "FileReply",
    "VoiceReply",
  ]),
  MessageBarReplySrc: PropTypes.string,
  MessageBarReplyAvtarName: PropTypes.string,
  MessageBarReplyReplyText: PropTypes.string,
  MessageBarReplyAlt: PropTypes.string,
  messageReplyHandleCloseClick: PropTypes.func,

  // MessageBarAttachments
  isMessageBarAttachments: PropTypes.bool,
  hasImagesattachment: PropTypes.bool,
  hasVideosattachment: PropTypes.bool,
  hasFilesattachment: PropTypes.bool,
  hasVoiceattachment: PropTypes.bool,
  MessageBarAttachmentsImagesSrc: PropTypes.array,
  MessageBarAttachmentsVideosSrc: PropTypes.array,

  MessageBarAttachmentsAlt: PropTypes.string,
  MessageBarAttachmentsFiles: PropTypes.array,
  MessageBarAttachmentsDuration: PropTypes.string,

  // TypingIndicator
  isTypingIndicator: PropTypes.bool,
  TypingIndicatorType: PropTypes.oneOf(["name", "membersName", "count"]),
  TypingIndicatorName: PropTypes.string,
  TypingIndicatorMembersName: PropTypes.string,
  TypingIndicatorCount: PropTypes.number,

  // MessageBarActions
  isFilled: PropTypes.bool,
  AttachmentClickHandle: PropTypes.func,

  handlePreviewClick: PropTypes.func,
};

export default ChatMessageBar;
